import React from "react";
import ImgCargaCollar from "../img/step5/ImgCargaCollar";
import {
  component,
  flex_group,
  section_img,
  section_text,
} from "./steps.module.scss";
import FadeIn from "./components/SlideIn";
import SlideIn from "./components/SlideIn";

const StepsSix = () => {
  return (
    <div className={component}>
      <SlideIn>
        <h5>5. Cargar el collar</h5>
      </SlideIn>
      <div className={flex_group} style={{ padding: 0 }}>
        <SlideIn>
          <section className={section_text}>
            <ol>
              <li>
                Retira la ranura de silicona que hay en uno de los laterales del
                dispositivo del collar Kolyy.
              </li>
              <br />
              <li>
                Conecta el cable que encontrarás dentro de la caja en la ranura
                del lateral del dispositivo Kolyy y asegúrate que queda bien
                fijado.
              </li>
              <br />
              <li>Conéctalo a la corriente.</li>
            </ol>
          </section>
        </SlideIn>
        <FadeIn>
          <section className={section_img}>
            <div style={{ maxWidth: "450px", margin: "16px 0" }}>
              <ImgCargaCollar />
            </div>
          </section>
        </FadeIn>
      </div>
    </div>
  );
};

export default StepsSix;
