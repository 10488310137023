import React from "react";
import TextNumber from "./components/TextNumber/TextNumber";
import {
  component,
  flex_group_reverse,
  title,
  section_img,
  section_text,
} from "./steps.module.scss";
import FadeIn from "./components/SlideIn";
import SlideIn from "./components/SlideIn";
import IconSalud from "../img/step4/IconSalud";
import ImgDashboardSaludOne from "../img/step4/ImgDashboardSaludOne";
import ImgDashboardSaludTwo from "../img/step4/ImgDashboardSaludTwo";

const StepFiveTwo = () => {
  return (
    <div className={component}>
      <SlideIn>
        <div className={title}>
          <IconSalud /> <span>Dashboard de Salud:</span>
        </div>
      </SlideIn>
      <div className={flex_group_reverse}>
        <section className={section_text}>
          <SlideIn>
            <TextNumber number={5} title="Hacer un diagnóstico">
              <p>
                1. Haz clic en el botón “Empezar” y rellena el formulario.
                <br />
                <br />
                2. Envíalo a nuestros especialistas para que puedan realizar un
                diagnóstico de la condición física de tu perro.
                <br />
                <br />
                3. Recibe el informe en tu cuenta de correo y en el apartado
                “Ver diagnósticos” de la aplicación Kolyy.
              </p>
            </TextNumber>
          </SlideIn>
        </section>
        <FadeIn>
          <section className={section_img}>
            <ImgDashboardSaludOne />
          </section>
        </FadeIn>
      </div>
      <div className={flex_group_reverse}>
        <section className={section_text}>
          <TextNumber number={6} title="Mis diagnósticos">
            <SlideIn>
              <p>
                Haz clic en el botón “Ver diagnósticos” de la pantalla de Salud
                para ver el estado de tus informes.
                <br />
                <br />
                Fecha {"-->"} Día en que se realizó y se envió el diagnóstico.
              </p>
            </SlideIn>
            <br />
            <br />
            <SlideIn>
              <p>Estado del diagnóstico:</p>
              <ul>
                <li>
                  Analizando: Estado en fase de análisis donde nuestros expertos
                  veterinarios analizan la condición física del perro
                  diagnosticado.
                </li>
                <br />
                <li>
                  Finalizado: El informe ya está subido en la aplicación Kolyy y
                  enviado por correo electrónico.
                </li>
              </ul>
            </SlideIn>
            <br />
            <SlideIn>
              <p>
                <b>Calendario de diagnósticos:</b>
                <br />
                <br />
                Primer diagnóstico: 15 días después de activar por primera vez
                el collar Kolyy. Debe haber como mínimo 10 días de registro de
                actividad para un buen servicio de diagnóstico.
                <br />
                <br />
                Seguimiento: Cada 30 días se habilitará la opción de "actualizar"
                y volver a realizar un diagnóstico para un seguimiento
                recurrente de la salud y condición física de el perro.
              </p>
            </SlideIn>
          </TextNumber>
        </section>
        <FadeIn>
          <section className={section_img}>
            <ImgDashboardSaludTwo />
          </section>
        </FadeIn>
      </div>
    </div>
  );
};

export default StepFiveTwo;
