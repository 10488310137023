import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Linterna = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_blancas.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default Linterna;
