import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const IconSalud = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_icon_salud.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default IconSalud;
