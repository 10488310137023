import React from "react";
import SlideIn from "../SlideIn";
import {
  container,
  flex,
  numb,
  text,
  title_text,
  children_container,
} from "./textNumber.module.scss";

const TextNumber = ({ number, title, children }) => {
  return (
    <div className={container}>
      <div className={flex}>
        <SlideIn>
          <div className={numb}>
            <div>{number}</div>
          </div>
        </SlideIn>
        <div className={text}>
          <SlideIn>
            <p className={title_text}>{title}</p>
          </SlideIn>
          <div className={children_container}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default TextNumber;
