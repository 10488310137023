import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const IconCasaDangerTwo = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_icon_casa_alert_2.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default IconCasaDangerTwo;
