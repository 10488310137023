import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const CargaCollar = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_carga_collar.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default CargaCollar;
