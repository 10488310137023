import React from "react";
import {
  flex_group,
  section_text,
  flex_info,
  icon_img,
} from "../../steps.module.scss";
import location_feature_1 from "../../../../../images/icons/guia_icon_bluetooth.png";
import location_feature_2 from "../../../../../images/icons/guia_icon_loca.png";
import location_feature_3 from "../../../../../images/icons/guia_icon_gps.png";
import SlideIn from "../SlideIn";

const LocationFeatures = () => {
  return (
    <section>
      <SlideIn>
        <p>
          <b>Modos de localización:</b>
          <br />
          <br />
          El collar Kolyy utiliza diferentes sistemas de localización para
          maximizar la batería y la rapidez en la respuesta.
        </p>
      </SlideIn>
      <div className={flex_group}>
        <section className={section_text}>
          <SlideIn>
            {" "}
            <div className={flex_info}>
              <img
                className={icon_img}
                src={location_feature_1}
                alt="Localización por bluetooth"
              />
              <p>
                <b>
                  Localización por <i>bluetooth</i>:
                </b>{" "}
                Este sistema de localización funciona para un rango de 30-40
                metros donde a través del
                <i> bluetooth</i> de tu teléfono móvil localiza la posición del
                collar. Este sistema te da una posición rápida siempre que tu
                perro esté cerca.
              </p>
            </div>
          </SlideIn>

          <SlideIn>
            {" "}
            <div className={flex_info}>
              <img
                className={icon_img}
                src={location_feature_2}
                alt="Localización por zona"
              />
              <p>
                <b>Localización por zona:</b> Este sistema de localización se
                activa cuando el collar esta fuera de rango de <i>bluetooth</i>{" "}
                y no se ha activa la función “Perro Perdido”. Este sistema de
                localización te ofrece una aproximación de donde se encuentra tu
                perro en un área de 1 km.
              </p>
            </div>
          </SlideIn>
        </section>
        <section className={section_text}>
          <SlideIn>
            {" "}
            <div className={flex_info}>
              <img
                className={icon_img}
                src={location_feature_3}
                alt="Localización por GPS"
              />
              <p>
                <b>Localización por GPS:</b> Este sistema de localización se
                activa mediante la función “Perro Perdido”. El collar recibe la
                orden por parte de tu teléfono móvil que debe conectarse a la
                red móvil para buscar la posición de tu perro. El proceso de
                conectarse a la red puede tardar entre 1 y 5 minutos. <br />{" "}
                <br />
                Este sistema requiere de cobertura por parte tanto de tu
                telefono móvil como de tu collar. Si tu teléfono no tiene
                cobertura, no podrás recibir la posición que envía el collar.
              </p>
            </div>
          </SlideIn>
        </section>
      </div>
    </section>
  );
};

export default LocationFeatures;
