// extracted by mini-css-extract-plugin
export var component = "steps-module--component--B7mBw";
export var flex_group = "steps-module--flex_group--5D8Fw";
export var flex_group_first = "steps-module--flex_group_first--TEWPu";
export var flex_group_reverse = "steps-module--flex_group_reverse--rcy1r";
export var flex_info = "steps-module--flex_info--8LwUp";
export var flex_item = "steps-module--flex_item--T7hhm";
export var icon_img = "steps-module--icon_img--t3p8o";
export var img = "steps-module--img--TRvqJ";
export var img_big = "steps-module--img_big--NVDwG";
export var letter = "steps-module--letter--cssMl";
export var section_img = "steps-module--section_img--IvOoi";
export var section_text = "steps-module--section_text--Hz7pc";
export var span_img = "steps-module--span_img--UJJd7";
export var title = "steps-module--title--rv7Bt";