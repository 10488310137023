import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const IconCasaDangerOne = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_icon_casa_alert_1.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default IconCasaDangerOne;
