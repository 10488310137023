import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Connectividad = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_azules.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default Connectividad;
