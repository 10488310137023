import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const TresLucesVerdes = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_tres_verdes.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default TresLucesVerdes;
