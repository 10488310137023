import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ImgDashboardConfig = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_dashboard_config.png"
      alt="Guía de uso dashboard salud"
    />
  );
};

export default ImgDashboardConfig;
