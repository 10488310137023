import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const IconConnectividad = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/icon_connect.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default IconConnectividad;
