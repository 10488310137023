import React from "react";
import TextNumber from "./components/TextNumber/TextNumber";
import {
  component,
  flex_group_reverse,
  title,
  section_img,
  section_text,
} from "./steps.module.scss";
import FadeIn from "./components/SlideIn";
import SlideIn from "./components/SlideIn";
import ConfigOne from "../img/step4/ConfigOne";
import ConfigTwo from "../img/step4/ConfigTwo";
import ConfigThree from "../img/step4/ConfigThree";
import IconConfig from "../img/step4/IconConfig";
import ImgDashboardConfig from "../img/step4/ImgDashboardConfig";

const StepFiveThree = () => {
  return (
    <div className={component}>
      <SlideIn>
        <div className={title}>
          <IconConfig /> <span>Configuración:</span>
        </div>
      </SlideIn>
      <div className={flex_group_reverse} style={{ padding: 0 }}>
        <section className={section_text}>
          <div
            style={{
              maxWidth: "350px",
              margin: "16px 0",
              marginBottom: "32px",
            }}
          >
            <FadeIn>
              <ConfigOne />
            </FadeIn>
          </div>
          <SlideIn>
            <TextNumber number={7} title="Actualizar mi cuenta de usuario">
              <p>
                Edita tu información de cuenta si fuera necesario y haz clic en
                “Guardar” para actualizar tus datos.
              </p>
            </TextNumber>
          </SlideIn>
          <SlideIn>
            <TextNumber number={8} title="Cambiar contraseña">
              <p>
                Introduce la contraseña actual y después tu nueva contraseña de
                acceso a la aplicación. Haz clic en “Actualizar” para guardar
                los cambios y actualizar tu nueva contraseña.
              </p>
            </TextNumber>
          </SlideIn>
          <TextNumber number={9} title="Añadir o eliminar collares">
            <SlideIn>
              <p>
                Añadir collar:
                <br />
                <br />
                1. Haz clic en “Añadir Collar” para subir un nuevo collar en la
                aplicación. <br />
                <br />
                2. Completa los datos del perro al que quieras asignarle el
                nuevo collar. <br />
                <br />
                3. Guarda los datos para actualizar el perfil del nuevo perro y
                empezar a monitorizar su actividad y posición. <br />
                <br />
                <div style={{ maxWidth: "350px", margin: "16px 0" }}>
                  <ConfigTwo />
                </div>
              </p>
            </SlideIn>
            <SlideIn>
              <p>
                Eliminar collar:
                <br />
                <br />
                1. Haz clic sobre el perro del que quieres desvincular su
                collar.
                <br />
                <br />
                2. Asegúrate que el collar que deseas eliminar corresponde al
                perro seleccionado.
                <br />
                <br />
                3. Haz clic en “Eliminar” para finalizar el proceso y
                desvincular el collar de la aplicación. <br />
                <br />
                <br />
                <div style={{ maxWidth: "340px", margin: "16px 0" }}>
                  <ConfigThree />
                </div>
              </p>
            </SlideIn>
          </TextNumber>
          <SlideIn>
            <TextNumber number={10} title="Cerrar tu sesión">
              <p>
                Puedes cerrar tu sesión en el caso de que quieras cambiar de
                usuario o no quieras seguir utilizando la App de Kolyy.
              </p>
            </TextNumber>
          </SlideIn>
        </section>
        <FadeIn>
          <section className={section_img}>
            <ImgDashboardConfig />
          </section>
        </FadeIn>
      </div>
    </div>
  );
};

export default StepFiveThree;
