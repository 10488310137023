import React from 'react'
import { StaticImage } from "gatsby-plugin-image";

const PhoneImg = () => {
  return (
    <StaticImage
    placeholder="blurred"
    src="../../../../../images/icons/btn_call.png"
    alt="llámanos"
  />
  )
}

export default PhoneImg