import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const DosLucesVerdes = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_dos_verdes.png"
            alt="collar inteligente Kolyy"
    />
  );
};

export default DosLucesVerdes;
