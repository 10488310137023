import React from "react";
import IconActividad from "../img/step3/IconActividad";
import TextNumber from "./components/TextNumber/TextNumber";
import StepFourOne from "../img/step4/StepFourOne";
import StepFourTwo from "../img/step4/StepFourTwo";
import StepFourThree from "../img/step4/StepFourThree";
import FadeIn from "./components/SlideIn";
import SlideIn from "./components/SlideIn";
import {
  component,
  flex_group_reverse,
  title,
  section_img,
  section_text,
  flex_info,
  bigger_img,
  letter,
} from "./steps.module.scss";
import SectionFourTwoImg from "../img/step4/SectionFourTwoImg";
import SectionFourOneImg from "../img/step4/SectionFourOneImg";

const StepFour = () => {
  return (
    <div className={component}>
      <SlideIn>
        <h5>4. App</h5>
      </SlideIn>
      <SlideIn>
        <div className={title}>
          <IconActividad /> <span>Dashboard de Actividad:</span>
        </div>
      </SlideIn>

      <div className={flex_group_reverse}>
        <section className={section_text}>
          <TextNumber number={1} title="Objetivo de Actividad">
            <SlideIn>
              <p>
                Al abrir la aplicación aparecerá un objetivo establecido pero
                podrás cambiarlo cuando quieras.
                <br />
                <br />
                <b>¿Cómo cambiar el objetivo de actividad?</b>
              </p>
            </SlideIn>
            <br />
            <SlideIn>
              <div style={{ maxWidth: "350px", margin: "16px 0" }}>
                <StepFourTwo />
              </div>
            </SlideIn>
            <br />
            <SlideIn>
              <p>
                <b>¿Qué objetivo diario de actividad necesita mi perro?</b>
              </p>
            </SlideIn>
            <br />
            <SlideIn>
              <div style={{ maxWidth: "350px", margin: "16px 0" }}>
                <StepFourThree />
              </div>
            </SlideIn>
            <SlideIn>
              <div className={flex_info}>
                <div className={letter}>
                  <div>i</div>
                </div>
                <p>
                  Objetivo personalizado: completa el diagnóstico en la función
                  Salud para que nuestros especialistas puedan asignar un
                  objetivo ideal para tu perro.
                </p>
              </div>
            </SlideIn>
          </TextNumber>
        </section>
        <FadeIn>
          <section className={section_img}>
            <SectionFourOneImg />
          </section>
        </FadeIn>
      </div>

      <div className={flex_group_reverse}>
        <section className={section_text}>
          <SlideIn>
            <TextNumber number={2} title="Detalle de Actividad">
              <p>
                Debajo de la actividad diaria verás un resumen de los últimos 7
                días y el botón Detalles, desde el cuál podrás acceder a las
                métricas detalladas de cada día, semana, mes y últimos seis
                meses.
              </p>
              <div style={{ maxWidth: "350px", margin: "16px 0" }}>
                <StepFourOne />
              </div>
            </TextNumber>
          </SlideIn>
        </section>
        <FadeIn>
          <section className={section_img}>
            <SectionFourTwoImg />
          </section>
        </FadeIn>
      </div>
    </div>
  );
};

export default StepFour;
