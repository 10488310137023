import React from "react";
import CargaCollar from "../img/step1&2/CargaCollar";
import DescargaApp from "../img/step1&2/DescargaApp";
import ImgStepTwo from "../img/step1&2/ImgStepTwo";
import RegistraDatos from "../img/step1&2/RegistraDatos";
import FadeIn from "./components/SlideIn";
import SlideIn from "./components/SlideIn";
import {
  component,
  flex_group_first,
  flex_item,
  flex_group,
  section_text,
  section_img,
} from "./steps.module.scss";

const items = [
  {
    img: <CargaCollar />,
    text: "1. Carga el collar.",
    index: 0,
  },
  {
    img: <DescargaApp />,
    text: "2. Descarga la App.",
    index: 1,
  },
  {
    img: <RegistraDatos />,
    text: "3. Sigue las instrucciones en la App para activar y vincular tu collar.",
    index: 2,
  },
];

const StepsOneTwo = () => {
  return (
    <div className={component}>
      <SlideIn>
        <h5>1. Pasos para empezar</h5>
      </SlideIn>
      <section className={flex_group_first}>
        {items.map((item) => {
          return (
            <SlideIn>
              <div className={flex_item} key={item.index}>
                {item.img}
                <p>{item.text}</p>
              </div>
            </SlideIn>
          );
        })}
      </section>
      <section className={flex_group}>
        <section className={section_text}>
          <SlideIn>
            <h5>2. Colocación del collar</h5>
          </SlideIn>
          <SlideIn>
            <p>Ajusta el collar al tamaño ideal del cuello de tu perro.</p>
          </SlideIn>
        </section>
        <FadeIn>
          <br />
          <section className={section_img}>
            <ImgStepTwo />
          </section>
        </FadeIn>
      </section>
    </div>
  );
};

export default StepsOneTwo;
