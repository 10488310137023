import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ConfigTwo = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_config_2.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default ConfigTwo;
