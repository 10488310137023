import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ImgDashboardFuncionCasa = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_funcion_casa.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default ImgDashboardFuncionCasa;
