import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ImgDashboardSaludOne = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_dashboard_salud_1.png"
      alt="Guía de uso dashboard salud"
    />
  );
};

export default ImgDashboardSaludOne;
