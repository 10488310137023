import React from "react";
import TextNumber from "./components/TextNumber/TextNumber";
import ImgDashboardPerroPerdido from "../img/step4/ImgDashboardPerroPerdido";
import BtnsPerroPerdido from "../img/step4/BtnsPerroPerdido";
import {
  component,
  flex_group_reverse,
  title,
  section_img,
  section_text,
  flex_info,
  img,
  img_big,
  span_img,
} from "./steps.module.scss";
import FadeIn from "./components/SlideIn";
import SlideIn from "./components/SlideIn";
import IconCasaDangerOne from "../img/step4/IconCasaDangerOne";
import IconCasaOne from "../img/step4/IconCasaOne";
import IconCasaDangerTwo from "../img/step4/IconCasaDangerTwo";
import IconCasaTwo from "../img/step4/IconCasaTwo";
import ImgDashboardFuncionCasa from "../img/step4/ImgDashboardFuncionCasa";
import IconLocation from "../img/step4/IconLocation";
import LocationFeatures from "./components/LocationFeatures/LocationFeatures";

const StepFive = () => {
  return (
    <div className={component}>
      <SlideIn>
        <div className={title}>
          <IconLocation /> <span>Dashboard de Localización:</span>
        </div>
      </SlideIn>
      <div className={flex_group_reverse}>
        <section className={section_text}>
          <TextNumber number={3} title="Función Perro Perdido">
            <SlideIn>
              <p>
                ¿Qué es y para qué sirve?
                <br />
                <br />
                La función Perro Perdido activa el <i>live tracking</i> del
                dispositivo para que localices a tu perro si se escapa o lo
                pierdes. No hay un límite de distancia, por muy lejos que estés
                de tu perro, esta función te permitirá encontrarlo. El
                dispositivo lleva integrado una tarjeta SIM y utiliza datos
                móviles para conectarse a la red para poder ofrecer una posición
                exacta de tu perro. Este proceso puede tardar entre 1 y 5 minutos,
                pero una vez has recibido la primera posición después se
                actualiza cada 30 segundos.
              </p>
            </SlideIn>

            <br />
            <br />
            <SlideIn>
              <p>
                El mapa va guardando las posiciones para que puedas ver la
                dirección que va tomando tu perro.
                <br />
                <br />
                Esta función requiere de un consumo elevado de batería por lo
                que recomendamos desactivar el botón “Encontrado” para ahorrar
                batería y alargar la autonomía del dispositivo.
              </p>
            </SlideIn>
            <br />
            <SlideIn>
              <div style={{ maxWidth: "350px", margin: "16px 0" }}>
                <BtnsPerroPerdido />
              </div>
            </SlideIn>
          </TextNumber>
        </section>
        <FadeIn>
          <section className={section_img}>
            <ImgDashboardPerroPerdido />
          </section>
        </FadeIn>
      </div>
      <div className={flex_group_reverse}>
        <section className={section_text}>
          <TextNumber number={4} title="Función Zona Segura">
            <SlideIn>
              <p>
                Esta función permite establecer una zona de seguridad mediante
                la conexión Wi-Fi.
              </p>
            </SlideIn>
            <br />
            <br />
            <SlideIn>
              <p>
                Leyenda colores:
                <div className={flex_info}>
                  <div className={img}>
                    <IconCasaDangerOne />
                  </div>
                  <p>No hay asignado ninguna zona de seguridad.</p>
                </div>
              </p>
            </SlideIn>
            <SlideIn>
              <div className={flex_info}>
                <div className={img}>
                  <IconCasaOne />
                </div>
                <p>Hay asignada una zona de seguridad.</p>
              </div>
            </SlideIn>
            <br />
            <br />
            <SlideIn>
              <p>
                <b>Activar una zona de seguridad:</b>
              </p>
            </SlideIn>
            <br />
            <br />
            <SlideIn>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>1. Haz clic en el botón "Casa".</p>
                <span className={span_img}>
                  <IconCasaDangerTwo />
                </span>
              </div>
            </SlideIn>
            <br />
            <br />
            <SlideIn>
              <p>
                2. Asegúrate que el Wi-Fi que aparece es el de tu hogar o
                corresponde al Wi-Fi que quieres marcar como zona de seguridad.
              </p>
            </SlideIn>
            <br />
            <br />
            <SlideIn>
              <p>
                3. Haz clic en “Aceptar” y tu zona de seguridad ya estará
                marcada.
              </p>
            </SlideIn>
            <br />
            <br />
            <SlideIn>
              <div className={flex_info}>
                <div className={img_big}>
                  <IconCasaTwo />
                </div>
                <p>
                  Si el icono de “Casa” está de color verde significa que tu
                  zona de seguridad está guardada.
                </p>
              </div>
            </SlideIn>
            <br />
            <br />
            <SlideIn>
              <p>
                Nota: Si quieres modificar tu zona de seguridad, haz clic en el
                botón verde “Casa” para eliminar el Wi-Fi guardado y asignar una
                nueva zona de seguridad con otro Wi-Fi.
              </p>
            </SlideIn>
          </TextNumber>
        </section>
        <FadeIn>
          <section className={section_img}>
            <ImgDashboardFuncionCasa />
          </section>
        </FadeIn>
      </div>
      <LocationFeatures />
    </div>
  );
};

export default StepFive;
