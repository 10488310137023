import React from "react";
import UnaLuzVerde from "../img/step3/UnaLuzVerde";
import DosLucesVerdes from "../img/step3/DosLucesVerdes";
import TresLucesVerdes from "../img/step3/TresLucesVerdes";
import TresLucesVerdesFijas from "../img/step3/TresLucesVerdesFijas";
import Bateria from "../img/step3/Bateria";
import Connectividad from "../img/step3/Connectividad";
import Linterna from "../img/step3/Linterna";
import IconActividad from "../img/step3/IconActividad";
import IconBateria from "../img/step3/IconBateria";
import IconConnectividad from "../img/step3/IconConnectividad";
import IconLinterna from "../img/step3/IconLinterna";
import SlideIn from "./components/SlideIn";
import { component, flex_group, flex_item, title } from "./steps.module.scss";
import UnaLuzBlanca from "../img/step3/UnaLuzBlanca";
import DosLucesBlancas from "../img/step3/DosLucesBlancas";

const items = [
  {
    img: <UnaLuzVerde />,
    title: "Una luz verde parpadea:",
    text: (
      <p>
        <b>Poca actividad </b>
        <br /> entre 1% y 33% del objetivo.
      </p>
    ),
    index: 0,
  },
  {
    img: <DosLucesVerdes />,
    title: "Una luz verde fija y segunda luz parpadeando:",
    text: (
      <p>
        <b>Actividad moderada </b>
        <br />
        entre el 34% y 66% del objetivo de actividad.
      </p>
    ),
    index: 1,
  },
  {
    img: <TresLucesVerdes />,
    title: "Dos luces verdes fijas y una parpadeando:",
    text: (
      <p>
        <b>Buena actividad </b>
        <br />
        entre el 67% y 100% del objetivo de actividad.
      </p>
    ),
    index: 2,
  },
  {
    img: <TresLucesVerdesFijas />,
    title: "Tres luces verdes fijas:",
    text: (
      <p>
        <b>Objetivo cumplido </b>
        <br />
        100% del objetivo cumplido.
      </p>
    ),
    index: 3,
  },
];

const bateria = [
  {
    img: <Bateria />,
    title: "Tres luces rojas parpadeando:",
    text: (
      <p>
        <b>Batería baja</b>
      </p>
    ),
    index: 0,
  },
  {
    img: <UnaLuzBlanca/>,
    title: "Una luz blanca parpadeando:",
    text: <p>Entre el 1% y 33% de carga.</p>,
    index: 1,
  },
  {
    img: <DosLucesBlancas/>,
    title: "Una luz blanca fija y segunda luz parpadeando:",
    text: <p>Entre el 33% y 66% de la carga.</p>,
    index: 2,
  },
  {
    img: <TresLucesVerdesFijas />,
    title: "Tres luces verdes fijas:",
    text: (
      <p>
        <b>Carga completa.</b>
      </p>
    ),
    index: 3,
  },
];

const items2 = [
  {
    img: <Connectividad />,
    title: (
      <div className={title}>
        <IconConnectividad /> <span>Emparejamiento:</span>
      </div>
    ),
    info: "Tres luces azules:",
    text: (
      <p>
        <b>Necesidad de emparejamiento con la App Kolyy. </b>
        <br />
        Abre la aplicación para que el collar se vincule y sincronice de manera correcta con la app de Kolyy.
      </p>
    ),
    index: 0,
  },
  {
    img: <Linterna />,
    title: (
      <div className={title}>
        <IconLinterna /> <span>Linterna:</span>
      </div>
    ),
    info: "Tres luces blancas:",
    text: (
      <p>
        <b>Modo linterna encendido</b>
      </p>
    ),
    index: 1,
  },
  {
    img: "",
    info: "",
    title: "",
    text: "",
    index: 2,
  },
  {
    img: "",
    info: "",
    title: "",
    text: "",
    index: 3,
  },
];

const StepThree = () => {
  return (
    <div className={component}>
      <SlideIn>
        <h5>3. Luces del collar</h5>
      </SlideIn>
      <SlideIn>
        <div className={title}>
          <IconActividad /> <span>Actividad:</span>
        </div>
      </SlideIn>
      <section className={flex_group}>
        {items.map((item) => {
          return (
            <SlideIn>
              <div className={flex_item} key={item.index}>
                <p>{item.title}</p>
                <div style={{ margin: "0 auto" }}>{item.img}</div>
                {item.text}
              </div>
            </SlideIn>
          );
        })}
      </section>
      <SlideIn>
        <div className={title}>
          <IconBateria /> <span>Batería</span>
        </div>
      </SlideIn>
      <section className={flex_group}>
        {bateria.map((item) => {
          return (
            <SlideIn>
              <div className={flex_item} key={item.index}>
                <p>{item.title}</p>
                <div style={{ margin: "0 auto" }}>{item.img}</div>
                {item.text}
              </div>
            </SlideIn>
          );
        })}
      </section>
      <section className={flex_group}>
        {items2.map((item) => {
          return (
            <SlideIn>
              <div className={flex_item} key={item.index}>
                {item.title}
                <p>{item.info}</p>
                <div style={{ margin: "0 auto" }}>{item.img}</div>
                {item.text}
              </div>
            </SlideIn>
          );
        })}
      </section>
    </div>
  );
};

export default StepThree;
