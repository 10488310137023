import React from "react";
import TextNumber from "./components/TextNumber/TextNumber";
import {
  component,
  flex_group_reverse,
  title,
  section_img,
  section_text,
} from "./steps.module.scss";
import FadeIn from "./components/SlideIn";
import SlideIn from "./components/SlideIn";
import ImgDashboardMiPerro from "../img/step4/ImgDashboardMiPerro";
import IconMiPerro from "../img/step4/IconMiPerro";

const StepFiveFour = () => {
  return (
    <div className={component}>
      <SlideIn>
        <div className={title}>
          <IconMiPerro /> <span>Mi perro</span>
        </div>
      </SlideIn>
      <div className={flex_group_reverse}>
        <section className={section_text}>
          <SlideIn>
            <TextNumber number={11} title="Actualizar datos de mi perro">
              <p>
                Haz clic en el icono que aparece en la parte superior de la
                pantalla y actualiza sus datos si fuera necesario.
              </p>
            </TextNumber>
          </SlideIn>
        </section>
        <FadeIn>
          <section className={section_img}>
            <ImgDashboardMiPerro />
          </section>
        </FadeIn>
      </div>
    </div>
  );
};

export default StepFiveFour;
