import React from "react";
import Layout from "../components/layout/layout";
import { container } from "../styles/guiaDeUso.module.scss";
import StepsOneTwo from "../components/qrComponents/guiaDeUso/StepsOneTwo";
import StepThree from "../components/qrComponents/guiaDeUso/StepThree";
import StepFour from "../components/qrComponents/guiaDeUso/StepFour";
import StepFive from "../components/qrComponents/guiaDeUso/StepFive";
import StepFiveTwo from "../components/qrComponents/guiaDeUso/StepFiveTwo";
import StepFiveThree from "../components/qrComponents/guiaDeUso/StepFiveThree";
import StepFiveFour from "../components/qrComponents/guiaDeUso/StepFiveFour";
import StepsSix from "../components/qrComponents/guiaDeUso/StepSix";
import SlideIn from "../components/qrComponents/guiaDeUso/components/SlideIn.jsx"
import Contact from "../components/_Landing/Contact/Contact";

const GuiaUso = () => {
  return (
    <Layout>
      <div className={container}>
        <SlideIn>
        <h2>Guía de uso</h2>
        <p>
          ¡Hola kolyer! <br />
          En esta Guía vamos a explicarte paso por paso todas las funciones de
          la App Kolyy para que puedas disfrutarla al máximo.
        </p>
        </SlideIn>
        <StepsOneTwo />
        <StepThree />
        <StepFour />
        <StepFive />
        <StepFiveTwo />
        <StepFiveThree />
        <StepFiveFour />
        <StepsSix />
      </div>
        <Contact/>
    </Layout>
  );
};

export default GuiaUso;
