import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const DosLucesBlancas = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../images/guia_two_whites.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default DosLucesBlancas;
